<script setup lang="ts">
const cards = ref([
  {
    number: '160',
    text: 'Parceiros cadastrados',
  },
  {
    number: '20.000',
    text: 'Exames realizados',
  },
  {
    number: 'R$ 2 milhões',
    text: 'Economizados pelos pacientes',
  },
])
</script>

<template>
  <section class="bg-bg-50 py-14 md:py-20">
    <div class="container mx-auto px-5 lg:px-0">
      <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-[23%_1fr_1fr_25%] gap-6 mb-10">
        <div class="flex flex-col gap-2 justify-center">
          <h3 class="text-center md:text-left text-neutral-400 text-2xl font-sora font-semibold">
            A Saúde Trevo em números
          </h3>
          <p class="text-center md:text-left text-neutral-100 text-base">
            Números que impulsionam as nossas conquistas.
          </p>
        </div>
        <div
          v-for="(card, i) in cards"
          :key="i"
          class="p-6 rounded-lg border border-bg-200 flex flex-col justify-between gap-2"
        >
          <h4 class="text-primary-400 text-4xl font-sora">
            +{{ card.number }}
          </h4>
          <p class="text-neutral-100 text-xl">
            {{ card.text }}
          </p>
        </div>
      </div>
    </div>
  </section>
</template>
